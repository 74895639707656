<template>
  <div class="">
    <div class="navtop row-between mb20">
      <el-tabs v-model="activeName" type="card" @tab-click="resetList(1)">
        <el-tab-pane v-for="(item, index) in navList" :key="index" :name="item.value">
          <div slot="label">
            {{ item.label }} <span class="" v-if="statusNumber[item.value || 'all']">({{ (statusNumber[item.value ||
                'all'])
            }})</span>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="top-r flex1 row-end">
        <slot name="right"></slot>
      </div>
    </div>
    <!-- <div class="row-end screen" v-if="orderList.length">
      <el-checkbox v-model="checked" @change="handleCheckAllChange"
        >批量付款</el-checkbox
      >
      <div class="search-box row mb20">
        <el-input v-model="keyword" placeholder="请输入内容"></el-input>
        <button type="reset" class="lighter md">订单搜索</button>
      </div>
    </div> -->
    <el-form ref="form" :inline="true" :model="ruleForm" label-width="0px">
      <div class="row-between">
        <div class="row">
          <el-form-item>
            <el-input v-model="ruleForm.order_sn" placeholder="订单编号搜索"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.spu" placeholder="SPU搜索"></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker v-model="ruleForm.date" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" class="whtie submit" @click="resetList(1)">筛选</el-button>
          <el-button type="primary" class="whtie submit" @click="exportConsumeRecord">导出</el-button>
        </el-form-item>
      </div>
    </el-form>
    <div class="order-list mb20" v-if="orderList.length">
      <div class="order-item item-th row nr lighter">
        <div class="flex5"><span class="pd">商品</span></div>
        <div class="flex2 row-center">单价</div>
        <div class="flex1 row-center">数量</div>
        <div class="flex2 row-center">付款金额</div>
        <div class="flex2 row-center">订单状态</div>
        <div class="flex2 row-center">交易状态</div>
        <div class="flex2 row-center">交易操作</div>
      </div>
      <!-- <el-checkbox-group
        class="nr"
        v-model="checkList"
        @change="handleCheckedChange"
      > -->
      <div class="order-item item-pro mb10" v-for="(item, index) in orderList" :key="index">
        <div class="pro-title row-between nr lighter">
          <div class="row ml20">
            <span class="normal mr20">{{ item.create_time.slice(0, 10) }}</span>
            <span class="">订单编号：{{ item.order_sn }}</span>
          </div>
          <div class="row service xs">
            <img src="@/assets/icon/icon-service1.png" alt="" class="icon-md" />
            联系客服
            <!-- 订单状态：<span class="primary">{{
              getOrderStatus(item.order_status)
            }}</span> -->
          </div>
        </div>
        <div class="row pro-content wrap">
          <div class="flex2">
            <div class="row pro-item" v-for="(items, indexs) in item.order_goods" :key="indexs">
              <div class="flex5 pro-info row">
                <!-- <el-checkbox :label="indexs" class="ml20"></el-checkbox> -->

                <div class="ml20 mr20 img">
                  <el-image :src="items.image" lazy fit="cover" style="width: 100%; height: 100%"></el-image>
                </div>
                <div class="info-detail flex1 column">
                  <router-link :to="`/prodetail?id=${items.goods_id}`" class="name line1">{{ items.goods_name }}
                  </router-link>
                  <p class="attribute line1 lighter sm">
                    {{ items.spec_value }}
                  </p>
                  <div class="row">
                    <span class="xs row-center cursor" @click="toSalesApply(item, items)"
                      v-if="item.order_status == 3 && items.refund_status == 0">申请售后</span>
                    <span class="xs row-center cursor" v-if="item.order_status == 3 && items.is_comment == 0"
                      @click="tocomment(items.id)">填写评价</span>
                  </div>
                </div>
              </div>
              <div class="flex2 column pro-price">
                <!-- <make-priceformat
                :price="item.total_pay_price"
                :subscriptSize="17"
                :firstSize="17"
                :secondSize="17"
                :lineThrough="true"
                color="#999999"
              ></make-priceformat> -->
                <make-priceformat :price="items.goods_price" :subscriptSize="17" :firstSize="17" :secondSize="17"
                  color="#777777"></make-priceformat>
              </div>
              <div class="flex1 pro-num black row-center">
                {{ items.goods_num }}
              </div>
            </div>
          </div>

          <div class="flex2 row">
            <div class="flex2 column-center">
              <make-priceformat :price="item.order_amount" :subscriptSize="13" :firstSize="18" :secondSize="18">
              </make-priceformat>
              <div class="lighter xs mt5">(共{{ item.goods_count }}件)</div>
              <div class="xs price mt5" v-if="item.refund_status == '2'">
                （退款成功）
              </div>
            </div>
            <div class="flex2 column-center primary">
              {{ getOrderStatus(item.order_status) }}
            </div>
            <div class="flex2 pro-status column">
              <router-link :to="`/logistics?id=${item.id}`" v-if="item.delivery_btn">查看物流</router-link>
              <router-link :to="`/orderdetail?id=${item.id}`">订单详情</router-link>
            </div>
            <div class="flex2 pro-other column">
              <!-- <div class="time xs muted row" v-if="!item.result">
                  <img src="@/assets/icon/icon-time1.png" alt="" />还剩
                  <make-countdown
                    class="time-box row lg bold"
                    :startTime="1645522959"
                  ></make-countdown
                  >{{ item.result }}
                </div> -->
              <div class="time xs muted row" v-if="item.result">已结束</div>

              <!-- <router-link
                  :to="`/orderdetail?id=${item.id}`"
                  v-if="
                    item.pay_btn &&
                    item.finance_status == '1' &&
                    item.manager_status == '1'
                  "
                  >去付款</router-link
                > -->
              <button type="primary" class="status-btn white row-center" @click="
                $router.push({ path: '/orderdetail', query: { id: item.id } })
              " v-if="
  item.pay_btn &&
  item.finance_status == '1' &&
  item.manager_status == '1' && 
  (commonData.group_id == 1 || commonData.group_id == 4)
">
                去付款
              </button>
              <button type="primary" class="status-btn white row-center"
                @click="auditBox.order_id = item.id, auditBox.type = 1, dialogVisible = true" v-if="
                  item.pay_btn &&
                  item.finance_status == '0' &&
                  item.manager_status == '1'
                ">
                待审核(财务)
              </button>
              <button type="primary" class="status-btn white row-center"
                @click="auditBox.order_id = item.id, auditBox.type = 2, dialogVisible = true"
                v-if="item.pay_btn && item.manager_status == '0'">
                待审核(采购)
              </button>
              <button type="primary" class="status-btn white row-center" v-if="item.take_btn"
                @click="handleOrder(2, item.id)">
                确认收货
              </button>
              <button type="primary" class="status-btn white row-center" v-if="item.cancel_btn"
                @click="handleOrder(0, item.id)">
                取消订单
              </button>
              <button type="primary" class="status-btn white row-center" v-if="item.after_btn == 1"
                @click="handleRefund(item.id)">
                申请退款
              </button>
              <button type="reset" class="status-btn white row-center" v-if="item.after_btn == 2">
                已申请退款
              </button>
              <button type="primary" class="status-btn white row-center" v-if="item.del_btn"
                @click="handleOrder(1, item.id)">
                删除订单
              </button>
              <div class="xs price center" v-if="item.finance_status == '2'" @click="open(item.finance_reason)">
                已驳回(财务)
                <br>
                查看原因
              </div>
              <div class="xs price center" v-if="item.manager_status == '2'" @click="open(item.manager_reason)">
                已驳回(经理)
                <br>
                查看原因
              </div>
              <!-- <button
                @click="
                  $router.push({ path: '/evaluation', query: { id: item.id } })
                "
                type="primary"
                class="status-btn white row-center"
                v-if="item.comment_btn"
              >
                填写评价
              </button> -->
            </div>
          </div>
        </div>
      </div>
      <!-- </el-checkbox-group> -->
    </div>
    <el-empty description="暂无商品订单" v-else></el-empty>
    <div class="row-end">
      <make-pagination @resetList="resetList" :orderObject="orderObject" v-if="orderList.length"></make-pagination>
    </div>
    <el-dialog title="订单审核" :visible.sync="dialogVisible" width="30%">
      <el-radio-group v-model="auditBox.status" class="mb20">
        <el-radio :label="1" :value="1">通过审核</el-radio>
        <el-radio :label="2" :value="2">驳回审核</el-radio>
      </el-radio-group>
      <el-input v-if="auditBox.status == 2" type="textarea" :rows="4" placeholder="请输入驳回内容（驳回时必填）"
        v-model="auditBox.reason" resize="none">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setAudit()">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { download } from '@/utils/plugin'
import MakePagination from "@/components/MakePagination";
import MakeCountDown from "@/components/MakeCountDown";
import { mapState } from "vuex";
export default {
  name: "",
  props: {},
  components: {
    "make-pagination": MakePagination,
    "make-countdown": MakeCountDown,
  },
  data() {
    return {
      ruleForm: {
        spu: '',
        date: [],
        order_sn: ''
      },
      navList: [
        {
          label: "所有订单",
          value: "",
        },
        {
          label: "待审核",
          value: "examine",
        },
        {
          label: "待付款",
          value: "pay",
        },
        {
          label: "待发货",
          value: "send",
        },
        {
          label: "待收货",
          value: "delivery",
        },
        {
          label: "已完成",
          value: "finish",
        }, {
          label: "待评价",
          value: "comment",
        },
      ],
      checked: true,
      checkList: [0, 1],
      keyword: "",
      orderList: [],
      activeName: "",
      page_no: 1,
      page_size: 5,
      orderObject: {},
      orderId: "",
      statusNumber: {},
      dialogVisible: false,
      auditBox: {
        status: 1,
        order_id: '',
        type: '',
        reason: ''
      }
    };
  },
  computed: {
    getCancelTime() {
      return (time) => time - Date.now() / 1000;
    },
    ...mapState({
      commonData: (state) => state.user.commonData,
    }),
  },
  watch: {},
  methods: {
    open(content) {
      this.$alert(content, '驳回原因', {
        confirmButtonText: '确定'
      });
    },
    handleRefund(id) {
      this.$router.push({
        path: "/salesapply",
        query: {
          order_id: id,
          type: 'all'
        },
      });
    },

    /**
     * @description 前往评价
     */
    tocomment(id) {
      this.$router.push({
        path: "/evaluation",
        query: {
          id,
        },
      });
    },
    /**
     * @description 前往售后
     * @param {Object} item 所属订单
     * @param {Object} items 商品详情
     */
    toSalesApply(item, items) {
      this.$router.push({
        path: "/salesapply",
        query: {
          order_id: item.id,
          item_id: items.item_id,
        },
      });
    },
    handleOrder(type, id) {
      this.type = type;
      this.orderId = id;
      this.$confirm(this.getTipsText(type), {
        title: "温馨提示",
        center: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            this.postOrder();
          }
        },
      });
    },
    async postOrder() {
      const { type, orderId } = this;
      let api;
      switch (type) {
        case 0:
          api = this.$api.cancelOrderApi;
          break;
        case 1:
          api = this.$api.delOrderApi;
          break;
        case 2:
          api = this.$api.confirmOrderApi;
          break;
      }
      let { code, msg } = await api({ id: orderId });
      if (code == 1) {
        this.$message({
          message: msg,
          type: "success",
        });
        this.getOrderList();
      }
    },
    getTipsText(type) {
      switch (type) {
        case 0:
          return "确认取消订单吗？";
        case 1:
          return "确认删除订单吗?";
        case 2:
          return "确认收货吗?";
      }
    },
    getOrderStatus(status) {
      let text = "";
      switch (status) {
        case 0:
          text = "待付款";
          break;
        case 1:
          text = "等待发货";
          break;
        case 2:
          text = "等待确认收货";
          break;
        case 3:
          text = "交易成功";
          break;
        case 4:
          text = "已关闭";
          break;
      }
      return text;
    },
    async setAudit() {
      let { auditBox } = this;
      if (auditBox.status == 2 && auditBox.reason == '') {
        return this.$message({
          message: '请填写驳回内容',
          type: "warning",
        });
      }
      let api =
        auditBox.type == 1
          ? this.$api.setFinanceExamineApi
          : this.$api.setManagerExamineApi;
      let { code, msg } = await api(auditBox);
      if (code == 1) {
        this.$message({
          message: msg,
          type: "success",
        });
        this.auditBox.reason = ''
        this.dialogVisible = false;
      }
      this.resetList();
    },
    resetList(e) {
      this.page_no = e;
      this.getOrderList();
    },
    handleCheckAllChange(val) {
      this.checkList = val ? [0, 1, 2] : [];
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checked = checkedCount === [0, 1, 2].length;
    },
    /**
     * @description 获取我的订单数据
     */
    getStatusNumber() {
      this.$api.getStatusNumberApi().then((res) => {
        this.statusNumber = res.data;
      });
    },
    /**
     * @description 获取订单列表
     * @returns void
     */
    getOrderList() {
      this.getStatusNumber();
      let { page_no, page_size, activeName: type } = this;
      let { date, spu, order_sn } = this.ruleForm;
      this.$api
        .getOrderListApi({
          type,
          page_no,
          page_size,
          spu,
          order_sn,
          start_time: date && date[0],
          end_time: date && date[1]
        })
        .then((res) => {
          this.orderList = res.data.list;
          this.orderObject = res.data;
        });
    },
    exportConsumeRecord() {
      let { page_no, page_size, activeName: type } = this;
      let { date, spu, order_sn } = this.ruleForm;
      download('/order/orderExcel', {
        type,
        page_no,
        page_size,
        spu,
        order_sn,
        start_time: date && date[0],
        end_time: date && date[1]
      }, `订单列表${new Date().getTime()}.xlsx`)
    },
  },
  created() {
    this.activeName = this.$route.query.type;
    this.getOrderList();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
.screen {
  .search-box {
    height: 45px;
    border: 2px solid #c7c7c7;
    background-color: #c7c7c7;

    .el-input {
      width: 262px;
    }

    /deep/ .el-input__inner {
      border: none;
      height: 41px;
      line-height: 1;
      border-radius: 0;
    }

    button {
      height: 41px;
      line-height: 1;
      width: 107px;
      background-color: #c7c7c7;
      cursor: pointer;
    }
  }
}

.center.price {
  cursor: pointer;
}

.order-list {
  overflow: hidden;

  .order-item {
    /deep/ .el-checkbox__label {
      display: none;
    }

    .flex5 {
      flex: 5;
      overflow: hidden;
    }

    >div {
      flex-shrink: 0;
      overflow: hidden;
    }

    &.item-th {
      height: 44px;
      background: $-color-center;
      //   padding-right: 25px;
      // padding-left: 40px;
      margin-bottom: 15px;

      .pd {
        padding-left: 36px;
        box-sizing: border-box;
      }
    }

    &.item-pro {
      box-shadow: 0px 1px 0px 0px rgba(184, 184, 184, 0.4);

      .pro-title {
        background-color: $-color-border;
        padding: 6px 0;

        .service {
          margin-right: 36px;

          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
            margin-right: 3px;
          }
        }
      }

      .pro-content {
        background-color: $-color-center;

        // padding-right: 25px;
        >div {
          flex-shrink: 0;
          overflow: hidden;
        }

        .pro-item {
          border-bottom: $-solid-border;
          border-right: $-solid-border;

          &:last-child {
            border-bottom: none;
          }
        }

        .pro-info {
          padding: 30px 0 30px 15px;

          .info-detail {
            // padding-right: 20px;
            overflow: hidden;
            flex-shrink: 0;

            span {
              // position: absolute;
              // bottom: 0%;
              // left: 50%;
              // transform: translate(-50%, 20px);
              width: 70px;
              height: 20px;
              background-color: #e8f6ff;
              color: $-color-primary;
              border: 1px solid $-color-primary;
              border-radius: 3px;
              z-index: 2;
              margin-top: 5px;
              margin-right: 10px;
            }
          }

          .attribute {
            padding-right: 15px;
          }

          .img {
            width: 64px;
            height: 64px;
            position: relative;
          }
        }

        .pro-price {
          align-items: center;
        }

        .pro-status {
          text-align: center;
        }

        .pro-other {
          align-items: center;

          .time {
            margin-bottom: 5px;

            img {
              width: 14px;
              height: 14px;
            }
          }

          button {
            width: 90px;
            height: 35px;
            margin: 5px 0;
          }
        }
      }
    }
  }
}

.navtop {
  border-bottom: 1px solid $-color-primary;

  /deep/ .el-tabs {
    .el-tabs__header {
      border: none;
      margin-bottom: 0;

      .el-tabs__nav,
      .el-tabs__item {
        border: none;
      }

      .el-tabs__item {
        font-size: 18px;
        height: 46px;
        line-height: 46px;
        padding: 0 18px;

        &::after {
          content: "";
          width: 1px;
          height: 20px;
          background-color: $-color-border;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          // color: $-color-white;
        }

        &.is-active {
          background-color: $-color-primary;
          color: white;

          &::after {
            display: none;
          }
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

button {
  cursor: pointer;
}

/deep/ .el-dialog__body {
  padding: 20px;

  .el-radio__label {
    font-size: 16px;
  }
}
</style>